export default function Intro() {
    return (<>
        <div className="background bg1-layer1">
            <div className="background bg1-layer2">
            </div>
        </div>
        <div className="content profile" id="profile">
            <img src="profile-min.png" className="profile-image" alt="Jonathan in all his glory" />
            <h1>Jonathan Sundqvist</h1>
            <h2>Fullstack Developer</h2>
        </div>
      </>
    );
}