export default function Contact() {
    return (
        <div className="content content-background contact" id="contact">
            <h2>Contact</h2>
            <p>
                If you want to get in touch, I would mainly recommend sending an e-mail to &lt;jonathan.sundqvist[at]josu.se&gt;, since it's what I check most often.
            </p>
            <p>
                Otherwise, you're always welcome to get in touch by any of my socials:
                <ul>
                    <li><a target="_blank" rel="noreferrer" href="https://github.com/es5sujo">GitHub</a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/jonathan-sundqvist-842597221/">LinkedIn</a></li>
                </ul>
            </p>
        </div>
    );
}