export default function Projects() {
  return (
    <div className="content content-background projects" id="projects">
        <h2>Projects</h2>
        <div className="article">
          <img src="timekeep.png" alt="Main interface of Timekeep" />
          <div>
            <h3>Timekeep</h3>
            <p>
                A simple time keeping application for recording overtime and early leave. Built on top of Node.js and the Express framework.
            </p>
          </div>
        </div>
        <div className="article">
          <img src="satm.png" alt="Main of SATM" />
          <div>
            <h3>SATM</h3>
            <p>
                SATM (Simply Another Translation Manager) is a GUI tool written in Python for storing and managing 
                JSON language manifests. [<a target="_blank" rel="noreferrer" href="https://github.com/es5sujo/satm">GitHub</a>]
            </p>
          </div>
        </div>
        <div className="article">
          <div>
            <h3>ULib-SQL</h3>
            <p>
                MySQL plugin for the game <a target="_blank" rel="noreferrer" href="https://gmod.facepunch.com/">Garry's Mod</a> 
                for using an MySQL database for storing authorization data. Written in Lua to work with the server plugin 
                <a target="_blank" rel="noreferrer" href="http://ulyssesmod.net/about.php">ULib</a>. 
                [<a target="_blank" rel="noreferrer" href="https://github.com/es5sujo/ulib-sql">GitHub</a>]
            </p>
          </div>
        </div>
    </div>
  );
}