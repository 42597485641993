import Intro from './components/Intro';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import './App.css';

function App() {
  return (
    <>
      <Intro />
      <Projects />
      <About />
      <Contact />
    </>
  );
}

export default App;
