export default function About() {
    return (
        <div className="content content-background about" id="about">
            <h2>About</h2>
            <p>
                As a Fullstack Developer I mainly work with the .NET Core platform for backend and
                React and Typescript, but I also fancy other languages and platforms every now and then (mainly for the challenge ;-).
            </p>

            <p>
                Currently, I am working as a Lead Developer at <a target="_blank" rel="noreferrer" href="https://quickchannel.com">Quickchannel</a>
                 - one of Sweden's largest streaming platforms. There, I lead the Development team, help planning (lots of drawing on whiteboards included) 
                and participate in all sorts of cool development projects with everything from media delivery related things to working on old, obscure applications.
                As I've been working part time from (late) 2014 and full time from 2019, I can proudly say it's been quite the journey!
            </p>

            <p>
                Besides working, I usually like to tinker with a project or two - be that something computer related or 
                something electronics related (I have rarely dusted off the electronic components lately, though). Sometimes 
                something actually comes of it as seen in the projects section (but usually it just ends up in the "projects 
                that might be interesting again ... soon" bin). I also play the piano - mostly classical pieces.
            </p>

            <p>
                Working with code, I mainly feel comfortable working with:
                <ul>
                    <li>C# and .NET Core</li>
                    <li>Typescript/Javascript</li>
                    <li>React</li>
                    <li>Relational databases (MS SQL or MySQL)</li>
                    <li>Linux (only for hosting applications, though)</li>
                    <li>Git and Agile</li>
                </ul>
                Other things are fine too, under the right circumstances. :-)
            </p>
        </div>
    );
}